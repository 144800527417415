import { LayoutAuth, LayoutDefault } from '@/components/layouts'

export const publicRoute = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login',
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
        },
        component: () => import('@/views/auth/Login.vue'),
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      icon: '',
    },
    redirect: '/feed',
    children: [
       
      {
        path: '/feed',
        name: 'feed',
        meta: {
          title: 'feed',
          icon: 'mdi-factory',
        },
        component: () => import('@/views/Feed.vue'),
      },
      
      
     {
        path: '/userslist',
        meta: {
          title: 'userslist',
          icon: 'mdi-account-group-outline',
          hidden: true,
        },
        name: 'userslist',
        component: () => import('@/views/UsersList.vue'),
      },
      

      {
        path: '/settings',
        meta: {
          title: 'app_settings',
          icon: 'mdi-cogs',
        },
        name: 'app_settings',
        component: () => import('@/views/settings/AppSettings.vue'),
      },
      
      
      {
        path: '/userprofile',
        name: 'userprofile',
        meta: {
          title: 'userprofile',
          icon: 'mdi-card-account-details-outline',
          hidden: false,
        },
        
        component: () => import('@/views/Settings.vue'),
      },   
      {
        path: '/newuser',
        name: 'newuser',
        meta: {
          title: 'newuser',
          icon: 'mdi-card-account-details-outline',
          hidden: false,
        },
        
        component: () => import('@/components/user/NewUser.vue'),
      },   
      {
        path: '/403',
        name: 'Forbidden',
        meta: {
          title: 'access_denied',
          hidden: true,
        },
        component: () => import('@/views/error/Deny.vue'),
      },
    ],
  },

]

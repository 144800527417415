import request from '@/util/request'
import authApi from '@/api/auth'
import store from '..'


const state = {
  users:[],
  isSubmitting: false,
 // validationErrors: null
}

// getters

export const getterTypes = {
  getUsersList: '[user] getUsersList'
}
const getters = {
  [getterTypes.getUsersList]: (state) => {
   // console.log('state.users',state.users)
    return state.users.map((item) => {
     // console.log('item',item)
      return {
        name: item.name,
        login: item.login,
        role: item.role,
        active:item.active
      }
    })
  },
}

export const mutationTypes = {
  changePassStart: '[user] ChangePass start',
  changePassSuccess: '[user] ChangePass success',
  changePassFailure: '[user] ChangePass failure',
//  nullvalidationErrors: '[user] Null validationErrors',
  registerStart: '[user] Register start',
  registerSuccess: '[user] Register success',
  registerFailure: '[user] Register failure',
}

export const actionTypes = {
  register: '[user] Register',
  getUserById: '[user] get user by id',
  updateUserById:'[user] update user by id',
  deleteUserById: '[user] delete user by id',
  changePassUserById: '[user] change password user by id'
}
// actions


const actions = {
  [actionTypes.register](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerStart)
      authApi
        .register({
          name:credentials.nameUser,
          login:credentials.login,
          pass:credentials.password,
          email:'',
          role:credentials.role,
          active:credentials.active,
        }
          )
        .then(response => {
          context.commit(mutationTypes.registerSuccess,{} )
          
          resolve(response.data)
        })
        .catch(result => {
          store.commit('SHOW_SNACKBAR', {
            show: true,
            text: result.response.data.message,
            color: 'error',
         })
    /*      context.commit(
            mutationTypes.registerFailure,
            result.response.data.message
          ) */
         console.log('responce auth.js register result:', result.response.data.message)
        })
    })
  },
  fetchUsersList(context) {
    return new Promise(resolve => {
     authApi.userList()
    .then(response => {
     // console.log('/users response', response.data)
      context.commit('SET_USERS', response.data) 
      console.log('list', response)
      resolve(response.data)
    })
    .catch(result => {
      store.commit('SHOW_SNACKBAR', {
        show: true,
        text: 'gghfhfrdrfffggfg',
        color: 'error',
     })
/*      context.commit(
        mutationTypes.registerFailure,
        result.response.data.message
      ) */
     console.log('fetchUsersList:', result)
    })
  })
   
    
    
  },
  fetchUser(context, query) {
    return request({
      url: `/acl/user/`,
      method: 'get',
      params: query,
    })
  },
  createUser(context, data) {
    return request({
      url: `/acl/user/`,
      method: 'post',
      data: data,
    }).then((resp) => {
      return resp
    })
  },
  [actionTypes.updateUserById](context, { id, data }) {
    return request({
      url: `/acl/user/${id}`,
      method: 'put',
      data: data,
    }).then((resp) => {
      return resp
    })
  },
  [actionTypes.getUserById](context, id) {
  return new Promise(resolve => {
    authApi
        .user(id)
        .then(response => {
       //   context.commit(mutationTypes.loginSuccess, response.data.user)
          console.log('response.data', response.data)
      /*    setItem('token', response.data.access_token)
          context.dispatch(actionTypes.getCurrentUser) */
          resolve(response.data)
        })
    
      })
  },
  [actionTypes.deleteUserById](contex, id) {
    return new Promise(resolve => {
      
      authApi
          .deleteUserById(id)
          .then(response => {
            store.commit('SHOW_SNACKBAR', {
              show: true,
              text: 'Save user',
              color: 'success',
           })
           setTimeout(() => {
            store.commit('HIDE_SNACKBAR')
         }, 3000)
         //   context.commit(mutationTypes.loginSuccess, response.data.user)
            console.log('response.data', response.data)
        /*    setItem('token', response.data.access_token)
            context.dispatch(actionTypes.getCurrentUser) */
            resolve(response.data)
            
          })
      
        })
    },
    [actionTypes.changePassUserById](context, login){
      
      return new Promise(resolve => {
        console.log('changePassUserById: ', context)
       context.commit(mutationTypes.changePassStart)
       authApi
         .changePassUser(login)
         .then(password => {
           context.commit(mutationTypes.changePassStartSuccess, password)
           resolve(password)

         })
         .catch(result => {
           console.log("update failed:", result)
           context.commit(
             mutationTypes.changePassStartFailure,
             result.data.errors
           )
         })
     })
    }
}

// mutations
const mutations = {
  [mutationTypes.registerStart](state) {
    state.isSubmitting = true
   // state.validationErrors = null
  },
  [mutationTypes.registerSuccess](state, payload) {
    state.isSubmitting = false
    state.isAuthenticated = true
    state.user = payload
  },
  [mutationTypes.registerFailure](state) {
    state.isSubmitting = false
   // state.validationErrors = payload
  },
  SET_USERS(state, data) {
    state.users = data
  },
  [mutationTypes.changePassStart](state) {
    state.isSubmitting = true
    
  },
  [mutationTypes.changePassSuccess](state,) {
    state.isSubmitting = false
      },
  [mutationTypes.changePassFailure](state,) {
    state.isSubmitting = false
    
  },
 /* [mutationTypes.nullvalidationErrors](state) {
    state.validationErrors = null
   }, */
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <v-navigation-drawer
    v-model="showDrawer"
    app
    class="app-drawer"
    :rail="rail"
    rail-width="62"
    :width="drawerWidth"
  >
    <v-toolbar color="blue-lighten-5" dark alig > 
      <img :src="computeLogo" height="56" alt="Data collector" />
      <v-toolbar-title>
        <span class="hidden-sm-and-down">Data collector</span>
      </v-toolbar-title>
    </v-toolbar>
    
    <!-- <app-switcher /> -->
    <!-- <vue-perfect-scrollbar class="app-drawer__scrollbar">
      <div class="app-drawer__inner">
       
      </div>
     </vue-perfect-scrollbar> -->
    <nav-list :items="computeMenu" /> 
    <v-divider></v-divider>

    <template #append>
      <div color="primary-fon-blue-ligten">
        <template v-if="rail">
          <v-btn variant="tonal"  :rounded="0" height="48"  block class="mx-auto" @click.stop="rail = !rail" >
            <v-icon>mdi-arrow-collapse-right</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn  variant="tonal"  :rounded="0" right height="48"  block  @click.stop="rail = !rail" >
            <v-icon>mdi-arrow-collapse-left</v-icon>
          </v-btn>
        </template>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { protectedRoute as routes } from '@/router/config'
//import VuePerfectScrollbar from 'vue-perfect-scrollbar'
//import AppSwitcher from './AppSwitcher'
import NavList from '@/components/nav/NavList'

export default {
  name: 'AppDrawer',
  components: { NavList },
  props: {},
  data() {
    return {
      rail: false,
      showDrawer: true,
      drawerWidth: 256,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      userName: "hello",
    }
  },

  computed: {
    computeLogo() {
      return '/static/gears.png'
    },
    computeMenu() {
      return this.filterRouteItem(routes[0].children)
    },
    computeHeight() {
      return {
        height: this.height || '',
      }
    },
  },
  created() {},

  methods: {
    filterRouteItem(routes) {
      return routes
        .filter((item) => item.meta.hidden !== true)
        .map((item) => {
          return {
            title: this.$t(item.meta.title),
            icon: item.meta.icon,
            path: item.path,
            isNew: item.meta.isNew || false,
            children: item.children ? this.filterRouteItem(item.children) : [],
          }
        })
    },
    handleDrawerCollapse() {
       
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },
  },
}
</script>

<style lang="sass" scoped>
.app-drawer
  &__srollbar
    max-height:  calc(100vh - 64px - 36px - 44px)
  &__inner
    height: calc(100vh - 64px - 36px - 44px)
</style>

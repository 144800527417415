import 'vuetify/dist/vuetify.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import colors from 'vuetify/lib/util/colors'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import GoogleSignInPlugin from "vue3-google-signin"


  

createApp(App).use(vuetify).use(i18n).use(store).use(router).mount('#app')

App.use(GoogleSignInPlugin, {
    clientId: '375669719960-lensuh9iq28hphhlocgthtj54jdo0lni.apps.googleusercontent.com',
  });
  App.mount("#app");

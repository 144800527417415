//import Vue from 'vue'
import { createI18n } from 'vue-i18n'

//Vue.use(VueI18n)

import store from '../store'
//import zhHans from '@/locale/en.json'
import en from '@/locale/en.json'
const translation = {
  en: en,
  //zh: zhHans,
}
const i18n = createI18n({
  allowComposition: true,
  locale: store.getters.getLocale, // set locale
  messages: translation, // set locale messages
})

store.commit('SET_TRANSLATION', translation)

export default i18n

<template>
   <v-card tile>
      <v-toolbar  density="compact">
         <v-toolbar-title>Notification</v-toolbar-title>
         <v-btn text @click="handleClearNotification">clear</v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="pa-0">
         <v-list density="compact">
      <v-list-item
        v-for="(item, index) in items" 
        :key="index"
        :value="item"
        rounded="shaped">
        <template v-slot:prepend>
          <v-icon :color="item.color" :icon="item.icon"></v-icon>
        </template>
        <v-list-item-title v-text="item.title"></v-list-item-title>
        <v-list-item-action>{{ item.timeLabel }}</v-list-item-action>
        <v-divider />
      </v-list-item>
    </v-list>
         <v-divider></v-divider>
         <v-btn block text class="ma-0">All</v-btn>
         <v-divider></v-divider>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
   props: {
      items: {
         type: Array,
         default: () => [],
      },
   },
   data() {
      return {}
   },
   methods: {
      handleClick: (e) => {
         console.log(e)
      },
      handleClearNotification() {
         this.$store.dispatch('clearNotificaton')
      },
   },
}
</script>

